@font-face {
    font-family: 'ProximaNova';
    src: local('ProximaNova'), url('./fonts/1.woff') format('woff'), url('./fonts/2.woff') format('woff'),
        url('./fonts/3.woff') format('woff'), url('./fonts/4.woff') format('woff'), url('./fonts/5.woff') format('woff');
}

* {
    font-family: ProximaNova !important;
}

*:focus {
    outline: none !important;
}

.Toastify__toast-body {
    align-items: flex-start !important;
}

.Toastify__toast-icon {
    margin-top: 2px;
}
